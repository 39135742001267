body {
    margin: 0;
    padding: 0;
    font-family: Circular Std;
}

* {
    box-sizing: border-box;
    line-height: normal;
    font-family: inherit;
    margin: unset;
}

input {
    background-color: transparent;
    width: 100%;
}

input:focus {
    outline: none;
}

a {
    text-decoration: none;
    display: block;
}